import { getRandomArbitrary } from './utils';

var $imgs;
var running = false;
var index;

export function glitchInit(imgs) {
  $imgs = imgs;
}

export function glitchStartAnimation(indx) {
  let $img = $imgs.eq(indx);
  let data = $img.attr('src');
  let maxErrors = 100;
  let margin = 3000;

  running = true;
  index = indx;

  function update() {
    let corrupted = data;
    let errors = Math.round(Math.random() * maxErrors);
    for (let i = 0; i < errors; i++) {
      let p =
        margin + Math.round(Math.random() * (corrupted.length - margin - 1));
      corrupted =
        corrupted.substr(0, p) +
        corrupted.charAt(p + 1) +
        corrupted.charAt(p) +
        corrupted.substr(p + 2);
    }
    $img.attr('src', corrupted);

    setTimeout(function () {
      $img.attr('src', data);
    }, getRandomArbitrary(100, 700));

    if (running && index === indx) {
      setTimeout(function () {
        update();
      }, getRandomArbitrary(2000, 8000));
    }
  }

  setTimeout(update, 1000);
}

export function glitchStopAnimations() {
  running = false;
}
