import $ from 'jquery';
import { fillingText, stopAllVideos } from './utils';
import { glitchStartAnimation, glitchStopAnimations } from './glitch';

export default () => {
  const $menuList = $('.menu__item');
  const $videos = $('.player__pic');
  const $player = $('.jsVideoPlay');
  const $text = $('.menu__text');

  $menuList.on('mouseenter click', function () {
    const index = $(this).index();

    if ($(this).hasClass('menu__item_active')) {
      return false;
    }

    $(this)
      .addClass('menu__item_active')
      .siblings()
      .removeClass('menu__item_active');

    $videos
      .eq(index)
      .addClass('player__pic_active')
      .siblings()
      .removeClass('player__pic_active');

    glitchStartAnimation(index);

    stopAllVideos();

    return false;
  });

  $player.on('click', function () {
    const iframe = $(this).siblings('.youtube-video');

    iframe.attr('src', iframe.data('video'));
    $(this).closest('.youtube').addClass('youtube_play');
    glitchStopAnimations();

    return false;
  });

  $text.each(function () {
    const el = $(this);
    const text = el.data('text');

    el.html(fillingText(text));
  });
};
