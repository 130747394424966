import $ from 'jquery';

export function stopAllVideos() {
  $('.youtube-video')
    .each(function () {
      $(this).removeAttr('src');
    })
    .closest('.youtube')
    .removeClass('youtube_play');
}

export function fillingText(text) {
  let result = '';
  const count = 20;

  for (let index = 0; index < count; index++) {
    result += `<span>${text}</span>`;
  }

  return result;
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
