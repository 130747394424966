import 'babel-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import initFP from './fullpage';
import initPlayer from './player';
import { glitchInit } from './glitch';
import { init } from '@sentry/browser';

smoothscroll.polyfill();

init({
  dsn:
    'https://1472568b8d91471cae3542b1756f93c1@o400393.ingest.sentry.io/5258804',
  integrations(integrations) {
    return integrations.filter(
      (integration) => integration.name !== 'Breadcrumbs'
    );
  },
});

$(async function () {
  glitchInit($('.jsGlitchPic'));
  initFP();
  initPlayer();

  $('.service__item').on('mouseenter click', function () {
    const index = $(this).index();

    $(this).addClass('active').siblings().removeClass('active');
    $('.service-media__item')
      .removeClass('active')
      .eq(index)
      .addClass('active');
  });

  await import('./team');
  await import('./plax');
  await import('./contact');
});
