import $ from 'jquery';
import 'fullpage.js';
import { stopAllVideos } from './utils';
import { glitchStartAnimation, glitchStopAnimations } from './glitch';

function promoGlitch() {
  let promoIndex = 0;
  let timer;
  let worked = false;
  const promoPics = $('.promo-glitch');
  const promoLength = promoPics.length;
  const promoDuration = 10000;
  const promoDelay = 1500;
  return {
    start() {
      promoPics.eq(promoIndex).addClass('active');
      worked = true;
      timer = setInterval(() => {
        promoIndex++;
        if (promoIndex >= promoLength) promoIndex = 0;
        promoPics.eq(promoIndex).addClass('active');
        setTimeout(() => {
          if (worked) {
            promoPics.removeClass('active').eq(promoIndex).addClass('active');
          } else {
            promoPics.removeClass('active');
          }
        }, promoDelay);
      }, promoDuration - promoDelay);
    },
    stop() {
      clearInterval(timer);
      promoPics.removeClass('active');
      worked = false;
    },
  };
}

export default () => {
  const $container = $('.fullpage');
  const $page = $('.page');
  const $nav = $('.nav__list > ul > li');
  const $logo = $('.logo');
  const $buttonScrollTop = $('.jsScrollTop');
  const $buttonScrollDown = $('.jsScrollNext');
  const sectionLength = $container.find(' > .fullpage__section').length;
  const promoScreen = promoGlitch();

  $container.fullpage({
    // anchors: ['1', '2', '3', '4', '5'],
    animateAnchor: false,
    verticalCentered: true,
    afterLoad: (anchor, index) => {
      if (index === 1) {
        promoScreen.start();
      } else {
        promoScreen.stop();
      }
    },
    onLeave: (index, nextIndex) => {
      if (index === 2) {
        setTimeout(() => {
          stopAllVideos();
          glitchStopAnimations();
        }, 350);
      }

      if (nextIndex === 2) {
        glitchStartAnimation($('.player__pic_active').index());
      }

      if (sectionLength === nextIndex) {
        $page.addClass('page_last');
      } else {
        $page.removeClass('page_last');
      }

      $nav
        .removeClass('active')
        .eq(nextIndex > 1 ? nextIndex - 2 : undefined)
        .addClass('active');
    },
  });

  $logo.on('click', function () {
    $.fn.fullpage.silentMoveTo(0);
  });

  $buttonScrollTop.on('click', function () {
    $.fn.fullpage.silentMoveTo(1);
  });
  $buttonScrollDown.on('click', function () {
    $.fn.fullpage.moveSectionDown();
  });

  $nav.find('> a').on('click', function () {
    const index = $(this).closest('li').index();

    $.fn.fullpage.silentMoveTo(index + 2);
    $('.jsNav').removeClass('active');
    // if ($('.jsNav').hasClass('active')) {
    //   $.fn.fullpage.silentMoveTo(index + 2);
    //   $('.jsNav').removeClass('active');
    // } else {
    //   $.fn.fullpage.moveTo(index + 2);
    // }
  });

  $('.jsNavToggle').on('click', function () {
    $('.jsNav').toggleClass('active');
  });
};
